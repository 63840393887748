<script setup></script>

<template>
  <main
    class="layout-default"
    :class="[$route.path === '/' ? 'homepage' : 'standard-page']"
  >
    <SeoTemplatePreset>
      <NavigationHeader />
      <slot />
      <FixedBlock v-if="$route.path !== '/questionario-conformita'" />
      <Footer />
    </SeoTemplatePreset>
  </main>
</template>
