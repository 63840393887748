<script setup>
import { serviziMenuNav } from '@/graphql/queries/servizi.js'

const { locale } = useI18n()

const variables = computed(() => ({
  locale: locale.value,
}))

const container = ref(null)

const { data } = await useGraphql(serviziMenuNav, variables)

if (!data.value.allListaServizis) {
  throw createError({ statusCode: 404 })
}

const lista = computed(() => data?.value.allListaServizis || {})

const analizzaItems = computed(() =>
  lista.value.filter(
    (item) => item && item.suddivisioneServizioMenu === 'analizza',
  ),
)
const digitalizzaItems = computed(() =>
  lista.value.filter(
    (item) => item && item.suddivisioneServizioMenu === 'digitalizza',
  ),
)
const mettiANormaItems = computed(() =>
  lista.value.filter(
    (item) => item && item.suddivisioneServizioMenu === 'metti-a-norma',
  ),
)

onMounted(() => {
  updateVh()
  window.addEventListener('resize', updateVh)
  if (container.value) {
    container.value.classList.add('scrollable-content')
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateVh)
})

const updateVh = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const emit = defineEmits(['close', 'goBack'])

const emitEvents = () => {
  emit('goBack')
  emit('close')
}
</script>

<template>
  <div class="nav-menu-mobile second-level">
    <div class="nav-overlay-mobile__content">
      <div class="nav-overlay-mobile__links p-top-3">
        <div class="top-links">
          <div class="back-link">
            <img src="@/assets/img/ico/arrows-color2.svg" alt="Arrow" />
            <p class="back" @click="emit('goBack')">indietro</p>
          </div>
          <div class="servizi-link">
            <nuxt-link
              :to="
                localePath({
                  name: 'servizi',
                })
              "
              @click="emitEvents"
            >
              Tutti i Servizi
            </nuxt-link>
            <img src="@/assets/img/ico/arrows-color2.svg" alt="Arrow" />
          </div>
        </div>
        <div ref="container" class="container" data-lenis-prevent>
          <div v-if="analizzaItems.length > 0" class="row-1">
            <div class="img-text">
              <img
                src="@/assets/img/submenu/analizza.png"
                alt="Analizza"
                class="intro-img"
              />
              <h3 class="text">Analizza</h3>
            </div>
            <div
              v-for="(item, index) in analizzaItems"
              :key="'analizza-' + index"
              class="servizio"
              @click="emitEvents"
            >
              <nuxt-link
                v-if="item.slug"
                :to="
                  localePath({
                    name: 'servizi-slug',
                    params: { slug: item.slug },
                  })
                "
              >
                <div v-if="item.titolo">
                  {{ item.titolo }}
                </div>
              </nuxt-link>
            </div>
          </div>
          <div v-if="digitalizzaItems.length > 0" class="row-2">
            <div class="img-text">
              <img
                src="@/assets/img/submenu/digitalizza.png"
                alt="Digitalizza"
                class="intro-img"
              />
              <h3 class="text">Digitalizza</h3>
            </div>
            <div
              v-for="(item, index) in digitalizzaItems"
              :key="'digitalizza-' + index"
              class="servizio"
              @click="emitEvents"
            >
              <nuxt-link
                v-if="item.slug"
                :to="
                  localePath({
                    name: 'servizi-slug',
                    params: { slug: item.slug },
                  })
                "
              >
                <div v-if="item.titolo">
                  {{ item.titolo }}
                </div>
              </nuxt-link>
            </div>
          </div>
          <div v-if="mettiANormaItems.length > 0" class="row-3">
            <div class="img-text">
              <img
                src="@/assets/img/submenu/norma.png"
                alt="Metti a Norma"
                class="intro-img"
              />
              <h3 class="text">Metti a Norma</h3>
            </div>
            <div
              v-for="(item, index) in mettiANormaItems"
              :key="'metti-a-norma-' + index"
              class="servizio"
              @click="emitEvents"
            >
              <nuxt-link
                v-if="item.slug"
                :to="
                  localePath({
                    name: 'servizi-slug',
                    params: { slug: item.slug },
                  })
                "
              >
                <div v-if="item.titolo">
                  {{ item.titolo }}
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondLevelMenuMobile',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
