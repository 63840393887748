<script setup>
import { serviziMenuNav } from '@/graphql/queries/servizi.js'

const { locale } = useI18n()

const variables = computed(() => ({
  locale: locale.value,
}))

const { data } = await useGraphql(serviziMenuNav, variables)

if (!data.value || !data.value.allListaServizis) {
  throw createError({ statusCode: 404 })
}

const lista = computed(() => data?.value.allListaServizis || [])

const analizzaItems = computed(() =>
  lista.value.filter(
    (item) => item && item.suddivisioneServizioMenu === 'analizza',
  ),
)
const digitalizzaItems = computed(() =>
  lista.value.filter(
    (item) => item && item.suddivisioneServizioMenu === 'digitalizza',
  ),
)
const mettiANormaItems = computed(() =>
  lista.value.filter(
    (item) => item && item.suddivisioneServizioMenu === 'metti-a-norma',
  ),
)

const emit = defineEmits(['close'])
</script>

<template>
  <div class="nav-sub-menu row-120-20">
    <div v-if="analizzaItems.length > 0" class="column-1">
      <img
        src="@/assets/img/submenu/analizza.png"
        alt="Analizza"
        class="intro-img"
      />
      <h3 class="text-3 txt-bold">Analizza</h3>
      <p class="text-5 intro">
        Ci immergiamo nella realtà operativa di ogni dipartimento per
        comprendere i flussi in uso. L’obiettivo è diagnosticare accuratamente
        ogni fase del processo per scoprire inefficienze, duplicazioni e
        ritardi.
      </p>
      <div
        v-for="(item, index) in analizzaItems"
        :key="'analizza-' + index"
        class="servizio"
      >
        <nuxt-link
          v-if="item.slug"
          class="text-4"
          :to="
            localePath({ name: 'servizi-slug', params: { slug: item.slug } })
          "
          @click="emit('close')"
        >
          <div v-if="item.titolo" class="icon-link-animation">
            {{ item.titolo }}
            <img
              src="@/assets/img/ico/arrows-color2.svg"
              alt="Icon"
              class="icon"
            />
          </div>
        </nuxt-link>
      </div>
    </div>
    <div v-if="digitalizzaItems.length > 0" class="column-2">
      <img
        src="@/assets/img/submenu/digitalizza.png"
        alt="Digitalizza"
        class="intro-img"
      />

      <h3 class="text-3 txt-bold">Digitalizza</h3>
      <p class="text-5 intro">
        Guidiamo il processo di digitalizzazione di documenti e processi
        aziendali per ottimizzare i flussi, aumentare l’efficienza e ridurre i
        costi.
      </p>
      <div
        v-for="(item, index) in digitalizzaItems"
        :key="'digitalizza-' + index"
        class="servizio"
      >
        <nuxt-link
          v-if="item.slug"
          class="text-4"
          :to="
            localePath({ name: 'servizi-slug', params: { slug: item.slug } })
          "
          @click="emit('close')"
        >
          <div v-if="item.titolo" class="icon-link-animation">
            {{ item.titolo }}
            <img
              src="@/assets/img/ico/arrows-color2.svg"
              alt="Icon"
              class="icon"
            />
          </div>
        </nuxt-link>
      </div>
    </div>
    <div v-if="mettiANormaItems.length > 0" class="column-3">
      <img
        src="@/assets/img/submenu/norma.png"
        alt="Metti a norma"
        class="intro-img"
      />
      <h3 class="text-3 txt-bold">Metti a norma</h3>
      <p class="text-5 intro">
        Rendiamo compliant ogni documento in azienda, nel rispetto degli
        standard normativi vigenti, garantendo sicurezza e privacy.
      </p>
      <div
        v-for="(item, index) in mettiANormaItems"
        :key="'mettiANorma-' + index"
        class="servizio"
      >
        <nuxt-link
          v-if="item.slug"
          class="text-4"
          :to="
            localePath({ name: 'servizi-slug', params: { slug: item.slug } })
          "
          @click="emit('close')"
        >
          <div v-if="item.titolo" class="icon-link-animation">
            {{ item.titolo }}
            <img
              src="@/assets/img/ico/arrows-color2.svg"
              alt="Icon"
              class="icon"
            />
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuOverlay',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
