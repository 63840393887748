<template>
  <div class="footer-menu row-120-20 p-top-5 p-bot-2">
    <div class="footer-menu__content">
      <!-- Logo -->
      <div class="footer-menu__logo">
        <nuxt-link
          class="anim-opacity"
          :to="
            localePath({
              name: 'index',
            })
          "
        >
          <img
            src="@/assets/img/logo-digitxs-footer.svg"
            alt="DigitXS"
            loading="lazy"
          />
        </nuxt-link>
        <p class="info show-for-large digit-xs">
          Digital Excellence Solutions s.r.l.<br />
          Via Somalia 4 - 35141 - Padova (PD)<br />
          P.I. 05560080284 - Rea: 476019
        </p>
      </div>

      <!-- Footer links -->
      <div class="footer-menu__links text-4">
        <!-- Links Col1 -->
        <div class="main-links">
          <div>
            <ul class="footer-menu__list">
              <li class="footer-menu__item">
                <nuxt-link
                  :to="
                    localePath({
                      name: `servizi`,
                    })
                  "
                  class="underline-animation"
                >
                  Servizi
                </nuxt-link>
              </li>
              <li class="footer-menu__item">
                <nuxt-link
                  :to="
                    localePath({
                      name: `ambiti`,
                    })
                  "
                  class="underline-animation"
                >
                  Ambiti
                </nuxt-link>
              </li>
              <li class="footer-menu__item">
                <nuxt-link
                  :to="
                    localePath({
                      name: `metodo-adda`,
                    })
                  "
                  class="underline-animation"
                >
                  Metodo ADDA
                </nuxt-link>
              </li>
              <li class="footer-menu__item">
                <nuxt-link
                  :to="
                    localePath({
                      name: `azienda`,
                    })
                  "
                  class="underline-animation"
                >
                  Azienda
                </nuxt-link>
              </li>

              <li class="footer-menu__item">
                <nuxt-link
                  :to="
                    localePath({
                      name: `contatti`,
                    })
                  "
                  class="underline-animation"
                >
                  Contatti
                </nuxt-link>
              </li>
              <li class="footer-menu__item">
                <nuxt-link
                  :to="
                    localePath({
                      name: `questionario-conformita`,
                    })
                  "
                  class="underline-animation"
                >
                  Questionario di conformità
                </nuxt-link>
              </li>
            </ul>
          </div>
          <div class="info bottom-links show-for-large">
            <div class="policies">
              <nuxt-link
                :to="
                  localePath({
                    name: 'privacy-policy',
                  })
                "
                class="underline-animation"
                >Privacy Policy</nuxt-link
              >
              -
              <nuxt-link
                :to="
                  localePath({
                    name: 'cookie-policy',
                  })
                "
                class="underline-animation"
                >Cookie Policy</nuxt-link
              >
              -
              <nuxt-link
                :to="
                  localePath({
                    name: 'termini-condizioni',
                  })
                "
                class="underline-animation"
                >Termini & Condizioni</nuxt-link
              >
            </div>
            <br />
            <div>
              <a
                href="https://www.quamm.it/"
                target="_blank"
                class="underline-animation quamm"
              >
                Designed by Quamm</a
              >
            </div>
          </div>
        </div>
        <!-- Social -->
        <ul class="footer-menu__list">
          <li class="footer-menu__item">
            <a
              class="underline-animation"
              href="https://www.linkedin.com/company/digitxs/"
              target="_blank"
            >
              Linkedin
            </a>
          </li>
        </ul>
      </div>
      <div class="info bottom-links hide-for-large text-4">
        <div class="policies">
          <nuxt-link
            :to="
              localePath({
                name: 'privacy-policy',
              })
            "
            class="underline-animation"
            >Privacy Policy</nuxt-link
          >
          <nuxt-link
            :to="
              localePath({
                name: 'cookie-policy',
              })
            "
            class="underline-animation"
            >Cookie Policy</nuxt-link
          >
          <nuxt-link
            :to="
              localePath({
                name: 'termini-condizioni',
              })
            "
            class="underline-animation"
            >Termini & Condizioni</nuxt-link
          >
        </div>
        <br />
        <div>
          <a
            href="https://www.quamm.it/"
            target="_blank"
            class="underline-animation quamm"
          >
            Designed by Quamm</a
          >
        </div>
      </div>
      <p class="info bottom-info-mobile hide-for-large text-5">
        Digital Excellence Solutions s.r.l.<br />
        Via Somalia 4 - 35141 - Padova (PD)<br />
        P.I. 05560080284 - Rea: 476019
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuFooter',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
