<template>
  <footer class="footer">
    <FooterPreFooter
      v-if="
        $route.path !== '/contatti' &&
        $route.path !== '/questionario-conformita' &&
        $route.path !== '/privacy-policy' &&
        $route.path !== '/cookie-policy' &&
        $route.path !== '/termini-condizioni'
      "
    />
    <FooterMenu />
  </footer>
</template>

<script>
export default {
  name: 'FooterMain',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
