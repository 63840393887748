<script setup>
import { useMainStore } from '~/store'

const route = useRoute()
const headerClass = computed(() => {
  return route.path === '/' ? 'homepage' : 'standard-page'
})

const isServiziActive = computed(() => route.path.includes('/servizi/'))
const isAmbitiActive = computed(() => route.path.includes('/ambiti/'))

const store = useMainStore()

let closeTimeout
const isTriggeredFromServizi = ref(false)

const openOverlay = () => {
  if (!isTriggeredFromServizi.value) return
  clearTimeout(closeTimeout)
  store.setOverlay(true)
}

const closeOverlay = () => {
  store.setOverlay(false)
  isTriggeredFromServizi.value = false
}

const delayedCloseOverlay = () => {
  closeTimeout = setTimeout(() => {
    closeOverlay()
    isTriggeredFromServizi.value = false
  }, 300)
}

// Hide / Show nav
const lastScrollPosition = ref(0)
const header = ref(null)

const handleScroll = () => {
  const currentScrollPosition =
    window.scrollY || document.documentElement.scrollTop
  if (currentScrollPosition > lastScrollPosition.value) {
    // Scrolling down
    header.value.classList.add('hide-nav')
  } else {
    // Scrolling up
    header.value.classList.remove('hide-nav')
  }
  lastScrollPosition.value =
    currentScrollPosition <= 0 ? 0 : currentScrollPosition
  closeOverlay()
}
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  clearTimeout(closeTimeout)
})
</script>

<template>
  <div class="header__wrapper">
    <header
      ref="header"
      :class="[
        headerClass,
        {
          '--overlay-open': store.isOverlayOpen,
          '--overlay-open-mobile': store.open,
        },
      ]"
      class="main-menu row-40-20"
    >
      <!-- Navigation -->
      <nav class="main-menu__wrap">
        <div class="main-menu__left" @click="closeOverlay">
          <NavigationHamburger
            class="hide-for-large"
            :open="store.open"
            @toggle="store.toggleOverlay"
          />
          <!-- Navigation Logo -->
          <nuxt-link
            :to="localePath({ name: 'index' })"
            class="anim-opacity show-for-large"
          >
            <img
              src="@/assets/img/logo-digitxs.svg"
              alt="DigitXS"
              class="logo-digitxs"
            />
          </nuxt-link>
        </div>

        <div class="main-menu__center">
          <!-- Navigation Main Links -->
          <div class="main-menu__links show-for-large">
            <div
              class="main-menu__section-link text-3"
              :class="{ 'hover-state': store.isOverlayOpen }"
              @mouseover="
                () => {
                  isTriggeredFromServizi = true
                  openOverlay()
                }
              "
              @mouseout="delayedCloseOverlay"
              @click="closeOverlay"
            >
              <nuxt-link
                :to="localePath({ name: 'servizi' })"
                :class="{ 'active-state': isServiziActive }"
                class="underline-animation"
              >
                <div class="link-with-icon">
                  Servizi
                  <img
                    src="@/assets/img/ico/arrows.svg"
                    alt="Icon"
                    class="icon"
                  />
                </div>
              </nuxt-link>
            </div>

            <div class="main-menu__section-link text-3">
              <nuxt-link
                :to="
                  localePath({
                    name: 'ambiti',
                  })
                "
                :class="{ 'active-state': isAmbitiActive }"
                class="underline-animation"
              >
                Ambiti
              </nuxt-link>
            </div>

            <div class="main-menu__section-link text-3">
              <nuxt-link
                :to="
                  localePath({
                    name: 'metodo-adda',
                  })
                "
                class="underline-animation"
              >
                Metodo ADDA
              </nuxt-link>
            </div>
          </div>
        </div>

        <div class="main-menu__right">
          <div
            class="main-menu__section-link text-3 show-for-large azienda-link"
          >
            <nuxt-link
              :to="
                localePath({
                  name: 'azienda',
                })
              "
              class="underline-animation"
            >
              Azienda
            </nuxt-link>
          </div>

          <div class="main-menu__section-link text-3 show-for-large">
            <nuxt-link
              :to="
                localePath({
                  name: 'contatti',
                })
              "
              class="underline-animation"
            >
              Contatti
            </nuxt-link>
          </div>
          <!-- Navigation Logo -->
          <div class="mobile-logo">
            <nuxt-link
              :to="localePath({ name: 'index' })"
              class="anim-opacity hide-for-large"
            >
              <img
                src="@/assets/img/logo-digitxs.svg"
                alt="DigitXS"
                class="logo-digitxs"
              />
            </nuxt-link>
          </div>
        </div>
      </nav>

      <!-- Navigation Desktop Submenu Overlay  -->
      <NavigationOverlayDesktop
        :class="{ show: store.isOverlayOpen && isTriggeredFromServizi }"
        class="show-for-large"
        @mouseover="openOverlay"
        @mouseout="delayedCloseOverlay"
        @close="closeOverlay"
      />

      <!-- Navigation Mobile Overlay  -->
      <NavigationOverlayMobile
        :show="store.open"
        class="hide-for-large"
        @close="store.toggleOverlay"
      />
    </header>
  </div>
</template>

<script>
export default {
  name: 'NavigationHeader',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
