<script setup>
const emit = defineEmits(['close'])

const isSecondLevelMenuVisible = ref(false)

const toggleSecondLevelMenu = () => {
  isSecondLevelMenuVisible.value = !isSecondLevelMenuVisible.value
}

onMounted(() => {
  updateVh()
  window.addEventListener('resize', updateVh)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateVh)
})

const updateVh = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
</script>

<template>
  <div class="nav-menu-mobile">
    <div v-if="!isSecondLevelMenuVisible" class="nav-overlay-mobile__content">
      <!-- General links -->
      <ul class="nav-overlay-mobile__links p-top-3">
        <li class="nav-overlay-mobile__link" @click="toggleSecondLevelMenu">
          Servizi
        </li>
        <li class="nav-overlay-mobile__link">
          <nuxt-link
            :to="
              localePath({
                name: 'ambiti',
              })
            "
            @click="emit('close')"
          >
            Ambiti
          </nuxt-link>
        </li>
        <li class="nav-overlay-mobile__link">
          <nuxt-link
            :to="
              localePath({
                name: 'metodo-adda',
              })
            "
            @click="emit('close')"
          >
            Metodo ADDA
          </nuxt-link>
        </li>
        <li class="nav-overlay-mobile__link">
          <nuxt-link
            :to="
              localePath({
                name: 'questionario-conformita',
              })
            "
            @click="emit('close')"
          >
            Questionario conformità
          </nuxt-link>
        </li>
        <li class="nav-overlay-mobile__link">
          <nuxt-link
            :to="
              localePath({
                name: 'azienda',
              })
            "
            @click="emit('close')"
          >
            Azienda
          </nuxt-link>
        </li>
        <li class="nav-overlay-mobile__link">
          <nuxt-link
            :to="
              localePath({
                name: 'contatti',
              })
            "
            @click="emit('close')"
          >
            Contatti
          </nuxt-link>
        </li>
      </ul>

      <div class="nav-overlay-mobile__text">
        <a href="https://www.linkedin.com/company/digitxs/" target="_blank">
          <img src="@/assets/img/ico/linkedin.svg" alt="Linkedin" />
        </a>
        <div>
          <nuxt-link
            :to="
              localePath({
                name: 'privacy-policy',
              })
            "
            @click="emit('close')"
            >Privacy Policy</nuxt-link
          >
          -
          <nuxt-link
            :to="
              localePath({
                name: 'cookie-policy',
              })
            "
            @click="emit('close')"
            >Cookie Policy</nuxt-link
          >
        </div>
        <div>
          <nuxt-link
            :to="
              localePath({
                name: 'termini-condizioni',
              })
            "
            @click="emit('close')"
            >TERMINI & CONDIZIONI</nuxt-link
          >
        </div>
      </div>
    </div>
    <NavigationOverlayMobileSecondLevelMenu
      v-else
      @go-back="toggleSecondLevelMenu"
      @close="emit('close')"
    />
  </div>
</template>

<script>
export default {
  name: 'MenuMobile',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
