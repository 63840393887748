<script setup>
const emit = defineEmits(['close'])
</script>

<template>
  <div class="nav-overlay-desktop">
    <div class="nav-overlay-desktop__content">
      <NavigationOverlayDesktopMenu @close="emit('close')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlayDesktop',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
