<template>
  <div class="pre-footer row-230-20 p-bot-1 p-top-1 max">
    <div class="pre-footer__content">
      <!--<div class="pre-footer__item">
        <div class="pre-footer__item__icon">
          <img
            src="@/assets/img/pre-footer/anorc.svg"
            alt="DigitXS"
            loading="lazy"
          />
        </div>
        <div class="pre-footer__item__title text-3">
          Professionisti<br />
          accreditati Anorc
        </div>
      </div>-->
      <div class="pre-footer__item">
        <div class="pre-footer__item__icon">
          <img
            src="@/assets/img/pre-footer/approccio.svg"
            alt="DigitXS"
            loading="lazy"
          />
        </div>
        <div class="pre-footer__item__title text-3">
          Approccio<br />
          progettuale proprietario
        </div>
      </div>
      <div class="pre-footer__item">
        <div class="pre-footer__item__icon">
          <img
            src="@/assets/img/pre-footer/risultati.svg"
            alt="DigitXS"
            loading="lazy"
          />
        </div>
        <div class="pre-footer__item__title text-3">
          Risultati misurabili<br />
          su breve e lungo periodo
        </div>
      </div>
      <div class="pre-footer__item">
        <div class="pre-footer__item__icon">
          <img
            src="@/assets/img/pre-footer/esperienza.svg"
            alt="DigitXS"
            loading="lazy"
          />
        </div>
        <div class="pre-footer__item__title text-3">
          +20 anni di esperienza<br />come consulenti digitali
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreFooter',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
